/* .parent {
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
}

.file-item {
  list-style: none;
  background-color: #2065d1 38;
  display: flex;
  align-items: center;
} */
/* li svg {
  color: #f55e30;
} */
/* div svg:first-child {
  font-size: 1.2em;
  margin-right: 0.8em;
} */
/* div p {
  flex: 1;
  font-size: 0.9rem;
  padding-left: 10px;
}
div .actions {
  justify-self: flex-end;
  cursor: pointer;
}*/
div .actions .fa-spinner {
  font-size: 1.2em;
}
