.css-16d5wub-MuiFormHelperText-root {
  font-size: 1rem;
}

.input-container {
  display: flex;
  width: 250px;
  border: 1px solid;
  justify-content: space-between;
  border-radius: 10px;
  height: 3rem;
}
.input-container input:focus,
.input-container input:active {
  outline: none;
  border-radius: 10px;
}
.input-container input {
  border: none;
}
