.container {
  min-width: 700px;
  min-height: 330px;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1em;
}

/* display: flex;
flex-direction: column;  /* To make children "flow" downwards */
/* flex-wrap: wrap; */
