.text-editor {
  /* width: 100%; */
  /* font-size: 240px; */
  margin-top: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid;
  border-radius: 10px;
}
.ql-editor {
  min-height: 45px;
}

.quill > .ql-container {
  font-size: 16px;
}

.ql-container {
  width: calc(100% - 42px);
}

.react-quill-container {
  margin-left: 25px;
}
