body {
  /* background: #f3f1f2; */
  /* font-family: sans-serif; */
}

.app {
  /* margin: 1rem 4rem; */
}

.app .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  /* background: #fefcfc; */
}

/* Snow Theme */
/* .app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
} */

/* Bubble Theme */
.app .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}
.app .ql-snow.ql-toolbar {
  /* border: none !important; */
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}

.comment-section-ql {
  font-size: medium;
  max-height: 80px;
  overflow-y: auto;
  min-height: auto;
}

/* .comment-section-ql {
  min-height: 80px;
} */

/* .app .ql-editor {
  font-size: medium;
  max-height: 80px;
  overflow-y: auto;
  min-height: auto;
} */

/* .ql-editor {
  min-height: 80px;
} */

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}
.blurred-editor .ql-snow.ql-toolbar {
  display: none;
}
.redcolor {
  background-color: red;
}
.yellowColor {
  background-color: yellow;
}
/* test */

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-textfield {
  position: relative;
  /* height: 50vh !important; */
}

.material-textfield label {
  position: absolute;
  font-size: 0.9rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: #637381;
  padding: 0 0.3rem;
  margin: 0 0.6rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.app {
  font-size: 1rem;
  outline: none;
  border: 1px solid #2064d154;
  border-radius: 10px;
  padding: 1rem 0.7rem;
  color: #000000;
  transition: 0.1s ease-out;
}
.app:focus {
  border-color: #6200ee;
}
.app:focus + label {
  color: #6200ee;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.app:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
/* ::-webkit-scrollbar {
  display: none;
} */

.ql-container {
  height: 50vh !important;
}
