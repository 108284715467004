.commentContainer {
  top: 10%;
  left: 23%;
  width: 100%;
}
.documentListContainer {
  float: right;
  margin-right: -65px;
}

.commentText {
  display: inline;
  width: 100%;
  font-size: 16px;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
  font-size: 16px;
}

ol {
  padding-left: 20px;
}

ul {
  padding-left: 20px;
}

.ql-indent-1 {
  padding-left: 20px;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

/* .ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
} */
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
