.parent-container {
  border-radius: 5px;
  border: 1px solid black;
  padding: 1.2em 1.5em;
}
.li {
  list-style: none;
  background-color: #2065d1 38;
  display: flex;
  align-items: center;
}
/* li svg {
  color: #f55e30;
} */
li svg:first-child {
  font-size: 1.2em;
  /* margin-right: 0.8em; */
}
/* li p {
  flex: 1;
  font-size: 0.9rem;
  margin-left: 1em;
} */
.actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: flex-end;
  cursor: pointer;
}
/* li .actions .fa-spinner {
  font-size: 1.2em;
} */
